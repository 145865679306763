<template>
  <div class="flex flex-col text-left">
    <div class="w-full flex space-x-2">
      <div class="w-1/2 flex flex-col items-start">
        <label for="">Name</label>
        <input
          type="text"
          v-model="$v.roleprofile.name.$model"
          class="mb-2 w-full border rounded px-2 py-1 text-xs appearance-none focus:outline-none bg-transparent"
          :class="{
            'border-red-500': $v.roleprofile.name.$dirty && $v.roleprofile.name.$invalid,
          }"
          placeholder="Name"
        />
      </div>

      <div class="w-1/2 flex flex-col items-start">
        <label for="">Soll Summe Arbeitspensum</label>
        <input
          type="text"
          v-model="$v.roleprofile.target_sum_workload.$model"
          class="mb-2 w-full border rounded px-2 py-1 text-xs appearance-none focus:outline-none bg-transparent"
          :class="{
            'border-red-500':
              $v.roleprofile.target_sum_workload.$dirty &&
              $v.roleprofile.target_sum_workload.$invalid,
          }"
          placeholder="Soll Summe Arbeitspensum"
        />
      </div>
    </div>

    <div class="w-full flex space-x-2">
      <div class="w-1/2 flex flex-col items-start">
        <label for="">Vorgesetzte Rolle</label>
        <select
          v-model="roleprofile.supervisor"
          class="mb-2 w-full border rounded p-0.5 text-xs focus:outline-none bg-transparent"
        >
          <option value="">Vorgesetzte Rolle auswählen</option>
          <option
            v-for="(responsibility, index) in responsibilities"
            :key="`option-${index}`"
            :value="responsibility.id"
          >
            {{ responsibility.name }}
          </option>
        </select>
      </div>

      <div class="w-1/2 flex flex-col items-start">
        <label for="">Stellvertretende Rolle</label>
        <select
          v-model="roleprofile.deputy"
          class="mb-2 w-full border rounded p-0.5 text-xs focus:outline-none bg-transparent"
        >
          <option value="">Stellvertretende Rolle auswählen</option>
          <option v-for="(deputy, index) in deputies" :key="`option-${index}`" :value="deputy.id">
            {{ deputy.name }}
          </option>
        </select>
      </div>
    </div>

    <div class="w-full flex flex-col">
      <label for="">Beschreibung</label>
      <textarea
        v-model="roleprofile.description"
        class="mb-2 border rounded px-2 py-1 text-xs focus:outline-none bg-transparent"
      >
      </textarea>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { getAll, createOne, updateOne } from '@/services/role'

const tempRoleProfile = {
  name: '',
  supervisor: '',
  target_sum_workload: null,
  deputy: '',
  unit: '',
  description: '',
}

export default {
  name: 'new-role',
  props: ['payload'],
  components: {},
  data() {
    return {
      roleprofile: { ...tempRoleProfile },
      responsibilities: [],
      deputies: [],
    }
  },
  validations() {
    return {
      roleprofile: {
        name: {
          required,
        },
        target_sum_workload: {
          required,
        },
      },
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.roleprofile = {
        ...this.payload,
        unit: this.currentNode.id,
        deputy: this.payload && this.payload.deputy ? this.payload.deputy.id : '',
        supervisor: this.payload && this.payload.supervisor ? this.payload.supervisor.id : '',
      }
      getAll(false, true, true).then(response => {
        this.responsibilities = response.data.results
        this.deputies = response.data.results
      })
    },
    save() {
      if (this.$v.roleprofile.$invalid) {
        this.$toast.warning('Bitte füllen Sie die erforderlichen Felder aus')
      } else {
        if (this.roleprofile.id) {
          updateOne(this.roleprofile).then(() => {
            this.$emit('close-modal', { show: false })
            // this.$toast.success(response.data.message)
          })
        } else {
          this.roleprofile['unit'] = this.currentNode.id
          createOne(this.roleprofile).then(() => {
            this.$emit('close-modal', { show: false })
            // this.$toast.success(response.data.message)
          })
        }
      }
    },
  },
  watch: {},
}
</script>
